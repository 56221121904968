<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorias de ingreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-income-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Estatus de ingreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-income-status />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    KnIncomeCategories: () => import('@/components/configuration/KnIncomeCategories.vue'),
    KnIncomeStatus: () => import('@/components/configuration/KnIncomeStatus.vue')
  }
}
</script>

<style>

</style>