<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorías de proveedor</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-supplier-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sub categorías de proveedor</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-supplier-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    KnSupplierCategories: () => import('@/components/configuration/KnSupplierCategories.vue') ,
    KnSupplierSubcategories: () => import('@/components/configuration/KnSupplierSubcategories.vue')
  }
}
</script>

<style>
</style>