<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} motivo de movimiento de inventario`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        v-model="movReason.dato"
        class="mt-4"
        label="Nombre:"
        :rules="[rules.required]"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de motivos de movimiento de inventario"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeOptions"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue'),
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      movReason: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null
      },
      movementReasons: [],
      toUpdate: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeOptions() {
      return this.movementReasons.filter(movReason => movReason.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.movReason)
    }
  },
  async created() {
    this.movReason.id_autor = this.userData.id
    this.movReason.id_institucion_educativa = this.institutionId
    this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
  },
  methods: {
    async createOption() {
      try {
        const response = await this.postObj('/inventarios/crear-motivo-movimiento-inventario', {
          motivo_movimiento_inventario: this.movReason.dato,
          id_autor: this.movReason.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear el motivo de movimiento de inventario', error)
        this.errors.push('Error al intentar crear el motivo de movimiento de inventario')
      }
    },
    async updateOption() {
      try {
        const response = await this.postObj('/inventarios/update-motivo-movimiento-inventario', {
          id: this.movReason.id,
          motivo_movimiento: this.movReason.dato
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar actualizar el motivo de movimiento de inventario', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteOption(movReasonId) {
      try {
        const response = await this.postObj('/inventarios/delete-motivo-movimiento-inventario', {
          id_motivo_movimiento_inventario: movReasonId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar el motivo de movimiento de inventario', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateOption()
        this.toUpdate = false
      } else {
        await this.createOption()
        this.clear()
      }
      this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clear()
    },
    clear() {
      this.movReason = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.movReason = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.movReason = {...item}
      await this.updateOption()
      this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteOption(itemId)
      this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
      this.clearInline()
    }
  },
}
</script>
