<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} impuesto`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="tax.nombre_impuesto"
        label="Nombre impuesto:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model.number="tax.valor_impuesto"
        label="Valor:"
        :rules="[rules.required]"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de impuestos"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeTax">
          <v-list-item :key="item.nombre_impuesto">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === tax.id"
                  class="mb-0 pb-0"
                  v-model="tax.nombre_impuesto"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_impuesto"
                />

                <v-text-field
                  v-if="toUpdate && item.id === tax.id"
                  class="mb-0 pb-0"
                  v-model="tax.valor_impuesto"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  class="purple--text"
                  v-text="item.valor_impuesto * 100"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === tax.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeTax.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue')
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      tax: {
        id: 'NA', // No asignado
        nombre_impuesto: null,
        valor_impuesto: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      taxes: [],
      toUpdate: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeTax() {
      return this.taxes.filter(tax => tax.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.tax)
    }
  },
  async created() {
    this.tax.id_autor = this.userData.id
    this.tax.id_institucion_educativa = this.institutionId
    this.taxes = await this.fetchResultsByEI('productos', 'impuesto', this.institutionId)
  },
  methods: {
    async createTax() {
      try {
        const response = await this.postObj('/productos/crear-impuesto', {
          nombre_impuesto: this.tax.nombre_impuesto,
          valor_impuesto: this.tax.valor_impuesto / 100,
          id_institucion_educativa: this.tax.id_institucion_educativa,
          id_autor: this.tax.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear el impuesto', error)
        this.errors.push('Error al intentar crear el impuesto')
      }
    },
    async updateTax() {
      try {
        const response = await this.postObj('/productos/update-impuesto', {
          id: this.tax.id,
          nombre_impuesto: this.tax.nombre_impuesto,
          valor_impuesto: this.tax.valor_impuesto / 100  
        })
        if (response.error) {
          this.errors.push(response.error)
        }
        
      } catch (error) {
        console.error('Error al intentar actualizar el impuesto', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteTax(taxId) {
      try {
        const response = await this.postObj('/productos/delete-impuesto', {
          id_impuesto: taxId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar el impuesto', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateTax()
        this.toUpdate = false
      } else {
        await this.createTax()
        this.clear()
      }
      this.taxes = await this.fetchResultsByEI('productos', 'impuesto', this.institutionId)
      this.clear()
    },
    clear() {
      this.tax = {
        id: 'NA', // No asignado
        nombre_impuesto: null,
        valor_impuesto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.tax = {
        id: 'NA', // No asignado
        nombre_impuesto: null,
        valor_impuesto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.tax = {...item}
      this.tax.valor_impuesto = item.valor_impuesto * 100
      this.tax.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.tax);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteTax(itemId)
      this.taxes = await this.fetchResultsByEI('productos', 'impuesto', this.institutionId)
      this.clearInline()
    },
  },
}
</script>
