<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} Marca`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="brand.dato"
        label="Nombre marca:"
        :rules="[rules.required]"
      />
      <kn-doc-item
        class="py-0"
        idInput="brandImg"
        label="Imagen de la marca:"
        @saveFile="saveImage"
        @clearFile="clearImage"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de marcas"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeBrands">
          <v-list-item :key="item.dato">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === brand.id"
                  class="mb-0 pb-0"
                  v-model="brand.dato"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.dato"
                />
                <kn-doc-item
                  v-if="toUpdate && item.id === brand.id"
                  class="mb-0 pb-0"
                  :showLabel="false"
                  @saveFile="saveImage"
                  @clearFile="clearImage"
                />
                <v-img
                  v-else
                  :src="item.imagen"
                  height="100px"
                  max-width="100px"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === brand.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeBrands.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnDocItem: () => import('../forms/KnDocItem.vue')
  },
  mixins: [
    validationFormMixin,
    fileUtilsMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      brand: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      brands: [],
      toUpdate: false,
      imageFile: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeBrands() {
      return this.brands.filter(brand => brand.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.brand) || this.imageFile === null
    }
  },
  async created() {
    this.brand.id_autor = this.userData.id
    this.brand.id_institucion_educativa = this.institutionId
    this.brands = await this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
  },
  methods: {
    async createBrand() {
      try {
        const responseUpload = await this.uploadFile(this.imageFile)
        if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
          this.brand.ur_imagen = responseUpload.archivos[0].nombre_archivo

          const response = await this.postObj('/productos/crear-marca-producto', {
            marca: this.brand.dato,
            ur_imagen: this.brand.ur_imagen,
            id_institucion_educativa: this.brand.id_institucion_educativa,
            id_autor: this.brand.id_autor
          })
          if (response.error) {
            this.errors.push(response.error)
          }
        }
      } catch (error) {
        console.error('Error al intentar crear la marca', error)
        this.errors.push('Error al intentar crear la marca')
      }
    },
    async updateBrand() {
      try {
        const responseUpload = await this.uploadFile(this.imageFile)
        if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
          this.brand.ur_imagen = responseUpload.archivos[0].url_archivo

          const response = await this.postObj('/productos/update-marca-producto', {
            id: this.brand.id,
            marca_producto: this.brand.dato,
            url_imagen: this.brand.ur_imagen  
          })
          if (response.error) {
            this.errors.push(response.error)
          }
        }
      } catch (error) {
        console.error('Error al intentar actualizar la marca', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteBrand(brandId) {
      try {
        const response = await this.postObj('/productos/delete-marca-producto', {
          id_marca_producto: brandId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar la marca', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateBrand()
        this.toUpdate = false
      } else {
        await this.createBrand()
        this.clear()
      }
      this.brands = await this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
      this.clear()
    },
    clear() {
      this.brand = {
        id: 'NA', // No asignado
        dato: null,
        ur_imagen: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.imageFile = null
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.brand = {
        id: 'NA', // No asignado
        dato: null,
        ur_imagen: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.imageFile = null
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.brand = {...item}
      this.brand.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.brand);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteBrand(itemId)
      this.brands = await this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
      this.clearInline()
    },
    saveImage(obj) {
      this.imageFile = obj
    },
    clearImage() {
      this.imageFile = null
    }
  },
}
</script>
