<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} movimiento de inventario`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-select
        v-model="movement.id_producto"
        class="mt-4"
        label="Producto:"
        :rules="[rules.required]"
        :items="products"
        item-text="nombre_producto"
        item-value="id"
      />
      <kn-select
        v-model="movement.id_tipo_movimiento"
        label="Tipo de movimiento:"
        :rules="[rules.required]"
        :items="movementTypes"
        item-text="dato"
        item-value="id"
      />
      <kn-select
        v-model="movement.id_motivo_movimiento"
        label="Motivo del movimiento:"
        :rules="[rules.required]"
        :items="movementReasons"
        item-text="dato"
        item-value="id"
      />
      <kn-select
        v-model="movement.id_almacen"
        label="Almacén:"
        :rules="[rules.required]"
        :items="wharehouse"
        item-text="nombre_almacen"
        item-value="id"
      />
      <kn-text-field
        v-model="movement.inventario_inicial"
        label="Inventario inicial:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="movement.cantidad_movimiento"
        label="Inventario inicial:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="movement.inventario_final"
        label="Inventario final:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="movement.precio_unitario"
        label="Precio unitario:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="movement.valor_movimiento"
        label="Valor movimiento:"
        :rules="[rules.required]"
      />
      <kn-select
        v-model="movement.id_moneda"
        label="Moneda:"
        :rules="[rules.required]"
        :items="coins"
        item-text="nombre_moneda"
        item-value="id"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de movimientos de inventario"
      divider-position="bottom"
    />
    <v-data-table
      dense
      :headers="movementsHeaders"
      :items="inventoryMovements"
      item-key="id"
      loading-text="Cargando... Espera por favor"
      no-data-text="Aún no hay registros"
      no-results-text="No se encontraron coincidencias"
      :footer-props="{
        itemsPerPageText: 'Elementos por página'
      }"
    >
      <template #[`item.fecha_movimiento`]="{ item }">
        {{ item.fecha_movimiento.substring(0, 10) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnSelect: () => import('../inputs/KnSelect.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue')
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      movement: {
        id: 'NA', // No asignado
        id_producto: null,
        id_tipo_movimiento: null,
        id_motivo_movimiento: null,
        id_almacen: 1,
        inventario_inicial: null,
        cantidad_movimiento: null,
        inventario_final: null,
        precio_unitario: null,
        valor_movimiento: null,
        id_moneda: 1,
        id_autor: null,
        id_institucion_educativa: null
      },
      selectedBankAccount: {},
      inventoryMovements: [],
      banks: [],
      movements: [],
      products: [],
      movementTypes: [],
      movementReasons: [],
      wharehouse: [],
      coins: [],
      toUpdate: false,
      movementsHeaders: [
        { text: 'Fecha', value: 'fecha_movimiento' },
        { text: 'Producto', value: 'producto.nombre_producto' },
        { text: 'Tipo', value: 'tipo_movimiento.dato' },
        { text: 'Motivo', value: 'motivo_movimiento.dato' },
        { text: 'Cantidad anterior', value: 'inventario_inicial' },
        { text: 'Cantidad movimiento', value: 'cantidad_movimiento' },
        { text: 'Cantidad final', value: 'inventario_final' },
        { text: 'Precio unitario', value: 'precio_unitario' },
        { text: 'Total', value: 'valor_movimiento' },
      ],
      showMovements: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    invalidFields() {
      return this.objectHasNulls(this.movement)
    }
  },
  async created() {
    this.movement.id_autor = this.userData.id
    this.movement.id_institucion_educativa = this.institutionId
    this.inventoryMovements = await this.fetchResultsByEI('inventarios', 'movimiento-inventario', this.institutionId)
    this.products = await this.fetchResultsByEI('productos', 'producto', this.institutionId)
    this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
    this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
    this.wharehouse = await this.fetchResultsByEI('inventarios', 'almacen', this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
  },
  methods: {
    async createInventoryMovement() {
      try {
        const response = await api.post('/inventarios/crear-movimiento-inventario', {
          id_producto: this.movement.id_producto,
          id_tipo_movimiento: this.movement.id_tipo_movimiento,
          id_motivo_movimiento: this.movement.id_motivo_movimiento,
          id_almacen: this.movement.id_almacen,
          inventario_inicial: this.movement.inventario_inicial,
          cantidad_movimiento: this.movement.cantidad_movimiento,
          inventario_final: this.movement.inventario_final,
          precio_unitario: this.movement.precio_unitario,
          valor_movimiento: this.movement.valor_movimiento,
          id_moneda: this.movement.id_moneda,
          id_autor: this.movement.id_autor,
          id_institucion_educativa: this.movement.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear el movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateInventoryMovement()
        this.toUpdate = false
      } else {
        await this.createInventoryMovement()
        this.clear()
      }
      this.inventoryMovements = await this.fetchResultsByEI('inventarios', 'movimiento-inventario', this.institutionId)
      this.clear()
    },
    clear() {
      this.movement = {
        id: 'NA', // No asignado
        id_producto: null,
        id_tipo_movimiento: null,
        id_motivo_movimiento: null,
        id_almacen: 1,
        inventario_inicial: null,
        cantidad_movimiento: null,
        inventario_final: null,
        precio_unitario: null,
        valor_movimiento: null,
        id_moneda: 1,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    }
  },
}
</script>

<style>

</style>