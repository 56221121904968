<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} opción de producto`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="option.nombre_opcion"
        label="Nombre:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="option.descripcion"
        label="Descripción:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="option.valor"
        label="Valor:"
        :rules="[rules.required]"
      />
      <kn-select
        v-model="option.id_tipo_opcion"
        label="Tipo de opción:"
        :rules="[rules.required]"
        :items="types"
        item-text="dato"
        item-value="id"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de opciones de producto"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeOptions">
          <v-list-item :key="`${item.id}opcion`">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === option.id"
                  class="mb-0 pb-0"
                  v-model="option.nombre_opcion"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_opcion"
                />

                <kn-text-field
                  v-if="toUpdate && item.id === option.id"
                  class="my-0 py-0"
                  v-model="option.descripcion"
                  :showLabel="false"
                  :rules="[]"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.descripcion"
                />

                <kn-text-field
                  v-if="toUpdate && item.id === option.id"
                  class="my-0 py-0"
                  v-model="option.valor"
                  :showLabel="false"
                  :rules="[]"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Valor: ${item.valor}`"
                />
                
                <kn-select
                  v-if="toUpdate && item.id === option.id"
                  class="my-0 py-0"
                  v-model="option.id_tipo_opcion"
                  :showLabel="false"
                  :rules="[]"
                  :items="types"
                  item-text="dato"
                  item-value="id"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Tipo de opción: ${item.tipo_opcion.dato}`"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === option.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeOptions.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnSelect: () => import('../inputs/KnSelect.vue')
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      option: {
        id: 'NA', // No asignado
        nombre_opcion: null,
        descripcion: null,
        valor: null,
        id_tipo_opcion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      options: [],
      types: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeOptions() {
      return this.options.filter(option => option.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.option)
    }
  },
  async created() {
    this.option.id_autor = this.userData.id
    this.option.id_institucion_educativa = this.institutionId
    this.types = await this.fetchResultsByEI('productos', 'tipo-opcion-producto', this.institutionId)
    this.options = await this.fetchResultsByEI('productos', 'opciones-producto', this.institutionId)
  },
  methods: {
    async createOption() {
      try {
        const response = await this.postObj('/productos/crear-opciones-producto', {
          nombre_opcion: this.option.nombre_opcion,
          descripcion: this.option.descripcion,
          valor: this.option.valor,
          id_tipo_opcion: this.option.id_tipo_opcion,
          id_institucion_educativa: this.option.id_institucion_educativa,
          id_autor: this.option.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear la opción', error)
        this.errors.push('Error al intentar crear la opción')
      }
    },
    async updateOption() {
      try {
        const response = await this.postObj('/productos/update-opciones-producto', {
          id: this.option.id,
          nombre_opcion: this.option.nombre_opcion,
          descripcion: this.option.descripcion,
          valor: this.option.valor,
          id_tipo_opcion: this.option.id_tipo_opcion  
        })
        if (response.error) {
          this.errors.push(response.error)
        }
        
      } catch (error) {
        console.error('Error al intentar actualizar la opción', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteOption(optionId) {
      try {
        const response = await this.postObj('/productos/delete-opciones-producto', {
          id_opciones_producto: optionId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar la opción', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateOption()
        this.toUpdate = false
      } else {
        await this.createOption()
        this.clear()
      }
      this.options = await this.fetchResultsByEI('productos', 'opciones-producto', this.institutionId)
      this.clear()
    },
    clear() {
      this.option = {
        id: 'NA', // No asignado
        nombre_opcion: null,
        valor: null,
        descripcion: null,
        id_tipo_opcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.option = {
        id: 'NA', // No asignado
        nombre_opcion: null,
        valor: null,
        descripcion: null,
        id_tipo_opcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.option = {...item}
      this.option.id_tipo_opcion = item.tipo_opcion.id
      this.option.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.option);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteOption(itemId)
      this.options = await this.fetchResultsByEI('productos', 'opciones-producto', this.institutionId)
      this.clearInline()
    },
  },
}
</script>
