<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} unidad de medida`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="unit.dato"
        label="Unidad de medida:"
        :rules="[rules.required]"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de unidades de medida"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeUnits"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue'),
  },
  mixins: [
    validationFormMixin,
    fileUtilsMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      unit: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      units: [],
      toUpdate: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeUnits() {
      return this.units.filter(unit => unit.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.unit)
    }
  },
  async created() {
    this.unit.id_autor = this.userData.id
    this.unit.id_institucion_educativa = this.institutionId
    this.units = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
  },
  methods: {
    async createUnit() {
      try {
        const response = await this.postObj('/productos/create-unidad-medida', {
          unidad_medida: this.unit.dato,
          id_institucion: this.unit.id_institucion_educativa,
          id_autor: this.unit.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear la unidad medida', error)
        this.errors.push('Error al intentar crear la unidad medida')
      }
    },
    async updateUnit() {
      try {
        const response = await this.postObj('/productos/update-uom', {
          id: this.unit.id,
          unidad_medida: this.unit.dato
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar actualizar la unidad medida', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteUnit(unitId) {
      try {
        const response = await this.postObj('/productos/delete-uom', {
          id_uom: unitId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar la unidad medida', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateUnit()
        this.toUpdate = false
      } else {
        await this.createUnit()
        this.clear()
      }
      this.units = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
      this.clear()
    },
    clear() {
      this.unit = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.unit = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.unit = {...item}
      await this.updateUnit()
      this.units = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteUnit(itemId)
      this.units = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
      this.clearInline()
    }
  },
}
</script>
