<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Bancos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-banks />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Cuentas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-bank-account />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Formas de pago</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-payment-methods />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Penalizaciones</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-penalties />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Descuentos pronto pago</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-prompt-payment-discounts />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Colegiaturas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-financial-var-tuitions />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    KnFinancialVarBanks: () => import('@/components/configuration/KnFinancialVarBanks.vue'),
    KnFinancialVarBankAccount: () => import('@/components/configuration/KnFinancialVarBankAccount.vue'),
    KnFinancialVarPaymentMethods: () => import('@/components/configuration/KnFinancialVarPaymentMethods.vue'),
    KnFinancialVarPromptPaymentDiscounts: () => import('@/components/configuration/KnFinancialVarPromptPaymentDiscounts.vue'),
    KnFinancialVarTuitions: () => import('@/components/configuration/KnFinancialVarTuitions.vue'),
    KnFinancialVarPenalties: () => import('@/components/configuration/KnFinancialVarPenalties.vue')
  }
}
</script>

<style>

</style>