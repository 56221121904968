<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} Backorder`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="backorder.nombre"
        label="Nombre:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="backorder.tiempo_entrega_dias"
        label="Días de entrega:"
        :rules="[rules.required]"
      />
      <kn-check-box
        v-model="backorder.backorder"
        label="Backorder:"
        :rules="[]"
      />
      <kn-select
        v-model="backorder.id_marca_producto"
        label="Marca:"
        :rules="[rules.required]"
        :items="brands"
        item-text="dato"
        item-value="id"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de Backorders"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeBackorders">
          <v-list-item :key="`${item.id}Backorder`">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === backorder.id"
                  class="mb-0 pb-0"
                  v-model="backorder.nombre"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre"
                />

                <kn-text-field
                  v-if="toUpdate && item.id === backorder.id"
                  class="my-0 py-0"
                  v-model="backorder.tiempo_entrega_dias"
                  :showLabel="false"
                  :rules="[]"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Días de entrega: ${item.tiempo_entrega_dias}`"
                />

                <kn-check-box
                  v-if="toUpdate && item.id === backorder.id"
                  class="my-0 py-0"
                  v-model="backorder.backorder"
                  :rules="[]"
                  label="Backorder"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Backorder: ${item.backorder ? 'Sí' : 'No'}`"
                />
                
                <kn-select
                  v-if="toUpdate && item.id === backorder.id"
                  class="my-0 py-0"
                  v-model="backorder.id_marca_producto"
                  :showLabel="false"
                  :rules="[]"
                  :items="brands"
                  item-text="dato"
                  item-value="id"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Marca: ${item.fabricante.dato}`"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === backorder.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeBackorders.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnCheckBox: () => import('../inputs/KnCheckBox.vue'),
    KnSelect: () => import('../inputs/KnSelect.vue')
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      backorder: {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      backorders: [],
      brands: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeBackorders() {
      return this.backorders.filter(backorder => backorder.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.backorder)
    }
  },
  async created() {
    this.backorder.id_autor = this.userData.id
    this.backorder.id_institucion_educativa = this.institutionId
    this.brands = await this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
    this.backorders = await this.fetchResultsByEI('productos', 'backorder', this.institutionId)
  },
  methods: {
    async createBackorder() {
      try {
        const response = await this.postObj('/productos/crear-backorder', {
          nombre: this.backorder.nombre,
          backorder: this.backorder.backorder,
          tiempo_entrega_dias: this.backorder.tiempo_entrega_dias,
          id_marca_producto: this.backorder.id_marca_producto,
          id_institucion_educativa: this.backorder.id_institucion_educativa,
          id_autor: this.backorder.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear el backorder', error)
        this.errors.push('Error al intentar crear el backorder')
      }
    },
    async updateBackorder() {
      try {
        const response = await this.postObj('/productos/update-backorder', {
          id: this.backorder.id,
          nombre: this.backorder.nombre,
          backorder: this.backorder.backorder,
          tiempo_entrega_dias: this.backorder.tiempo_entrega_dias,
          id_marca: this.backorder.id_marca_producto  
        })
        if (response.error) {
          this.errors.push(response.error)
        }
        
      } catch (error) {
        console.error('Error al intentar actualizar el backorder', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteBackorder(backorderId) {
      try {
        const response = await this.postObj('/productos/delete-backorder', {
          id_backorder: backorderId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar el backorder', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateBackorder()
        this.toUpdate = false
      } else {
        await this.createBackorder()
        this.clear()
      }
      this.backorders = await this.fetchResultsByEI('productos', 'backorder', this.institutionId)
      this.clear()
    },
    clear() {
      this.backorder = {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.backorder = {
        id: 'NA', // No asignado
        nombre: null,
        backorder: false,
        tiempo_entrega_dias: null,
        id_marca_producto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.backorder = {...item}
      this.backorder.id_marca_producto = item.fabricante.id
      this.backorder.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.backorder);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteBackorder(itemId)
      this.backorders = await this.fetchResultsByEI('productos', 'backorder', this.institutionId)
      this.clearInline()
    },
  },
}
</script>
