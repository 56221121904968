<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Marcas</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-brands />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Códigos SAT</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-sat-codes />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorías de productos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Subcategorías de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Unidades de medida</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-uom />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Backorders</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-backorders />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Tipos de opciones de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-options-type />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Opciones de producto</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-options />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Impuestos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-product-taxes />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnProductBrands from '@/components/configuration/KnProductBrands.vue'
import KnProductCategories from '@/components/configuration/KnProductCategories.vue'
import KnProductSatCodes from '@/components/configuration/KnProductSatCodes.vue'
import KnProductSubcategories from '@/components/configuration/KnProductSubcategories.vue'
import KnProductUom from '@/components/configuration/KnProductUOM.vue'
import KnProductBackorders from '@/components/configuration/KnProductBackorders.vue'
import KnProductOptionsType from '@/components/configuration/KnProductOptionsType.vue'
import KnProductOptions from '@/components/configuration/KnProductOptions.vue'
import KnProductTaxes from '@/components/configuration/KnProductTaxes.vue'
export default {
  components: {
    KnProductBrands,
    KnProductSatCodes,
    KnProductCategories,
    KnProductSubcategories,
    KnProductUom,
    KnProductBackorders,
    KnProductOptionsType,
    KnProductOptions,
    KnProductTaxes,
  }
}
</script>

<style>
</style>