<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sucursales</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-sucursals />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Ciclo escolar</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-school-cycle />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Salones</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-classroom />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Grupos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-group-groups />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    KnGroupClassroom: () => import('@/components/configuration/KnGroupClassroom.vue'),
    KnGroupGroups: () => import('@/components/configuration/KnGroupGroups.vue'),
    KnGroupSucursals: () => import('@/components/configuration/KnGroupSucursals.vue'),
    KnGroupSchoolCycle: () => import('@/components/configuration/KnGroupSchoolCycle.vue')
  }
}
</script>

<style>

</style>