<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Categorias de egresos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-categories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Sub categorias de egresos</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-subcategories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Estatus de egreso</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-expense-status />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    KnExpenseCategories: () => import('@/components/configuration/KnExpenseCategories.vue'),
    KnExpenseSubcategories: () => import('@/components/configuration/KnExpenseSubcategories.vue'),
    KnExpenseStatus: () => import('@/components/configuration/KnExpenseStatus.vue')
  }
}
</script>

<style>

</style>