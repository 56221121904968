<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} código SAT`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="satCode.dato"
        label="Código SAT:"
        :rules="[rules.required]"
      />
      <kn-text-field
        v-model="satCode.descripcion"
        label="Descripción:"
        :rules="[rules.required]"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de códigos SAT"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeSATCodes">
          <v-list-item :key="item.dato">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === satCode.id"
                  class="mb-0 pb-0"
                  v-model="satCode.dato"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.dato"
                />
                <v-text-field
                  v-if="toUpdate && item.id === satCode.id"
                  class="mb-0 pb-0"
                  v-model="satCode.descripcion"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  class="purple--text"
                  v-text="item.descripcion"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === satCode.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeSATCodes.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue')
  },
  mixins: [
    validationFormMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      satCode: {
        id: 'NA', // No asignado
        dato: null,
        descripcion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      satCodes: [],
      toUpdate: false,
      imageFile: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeSATCodes() {
      return this.satCodes.filter(satCode => satCode.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.satCode)
    }
  },
  async created() {
    this.satCode.id_autor = this.userData.id
    this.satCode.id_institucion_educativa = this.institutionId
    this.satCodes = await this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
  },
  methods: {
    async createSATCode() {
      try {
        const response = await this.postObj('/productos/crear-categoria-sat', {
          codigo_sat: this.satCode.dato,
          descripcion: this.satCode.descripcion,
          id_institucion_educativa: this.satCode.id_institucion_educativa,
          id_autor: this.satCode.id_autor
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear el código SAT', error)
        this.errors.push('Error al intentar crear el código SAT')
      }
    },
    async updateSATCode() {
      try {
        const response = await this.postObj('/productos/update-codigo-sat', {
          id: this.satCode.id,
          codigo_sat: this.satCode.dato,
          descripcion: this.satCode.descripcion  
        })
        if (response.error) {
          this.errors.push(response.error)
        }
        
      } catch (error) {
        console.error('Error al intentar actualizar el código SAT', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteSATCode(satCodeId) {
      try {
        const response = await this.postObj('/productos/delete-codigos-sat', {
          id_codigo_sat: satCodeId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar el código SAT', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateSATCode()
        this.toUpdate = false
      } else {
        await this.createSATCode()
        this.clear()
      }
      this.satCodes = await this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
      this.clear()
    },
    clear() {
      this.satCode = {
        id: 'NA', // No asignado
        dato: null,
        descripcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.satCode = {
        id: 'NA', // No asignado
        dato: null,
        descripcion: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.satCode = {...item}
      this.satCode.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.satCode);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteSATCode(itemId)
      this.satCodes = await this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
      this.clearInline()
    },
  },
}
</script>
