<template>
  <div class="mt-10">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Tipos de movimiento de inventario</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movement-types />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Motivos de movimiento de inventario</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movement-reasons />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="primary--text text-h6">Movimientos de inventario</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kn-inventory-movements />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KnInventoryMovementReasons from '@/components/configuration/KnInventoryMovementReasons.vue'
import KnInventoryMovements from '@/components/configuration/KnInventoryMovements.vue'
import KnInventoryMovementTypes from '@/components/configuration/KnInventoryMovementTypes.vue'
export default {
  components: {
    KnInventoryMovementTypes,
    KnInventoryMovementReasons,
    KnInventoryMovements
  },
}
</script>

<style>

</style>