<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          md="8"
          lg="8"
          xl="5"
        >
          <v-row justify="center">
            <v-col cols="12">
              <h2 class="text-center">Configuración</h2>
            </v-col>
          </v-row>
          <kn-tabs
            :items="tabs"
            @tabValue="setTabValue"
          />
          <div v-if="valueDeterminate === 12.5">
            <expenses-setting />
          </div>
          <div v-if="valueDeterminate === 25">
            <income-setting />
          </div>
          <div v-if="valueDeterminate === 37.5">
            <employee-setting />
          </div>
          <div v-if="valueDeterminate === 50">
            <inventory-setting />
          </div>
          <div v-if="valueDeterminate === 62.5">
            <groups-setting />
          </div>
          <div v-if="valueDeterminate === 75">
            <financial-variables-setting />
          </div>
          <div v-if="valueDeterminate === 87.5">
            <suppliers-setting />
          </div>
          <div v-if="valueDeterminate === 100">
            <products-setting />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import KnTabs from '@/components/KnTabs.vue'
import EmployeeSetting from './EmployeeSetting.vue'
import ExpensesSetting from './ExpensesSetting.vue'
import FinancialVariablesSetting from './FinancialVariablesSetting.vue'
import GroupsSetting from './GroupsSetting.vue'
import IncomeSetting from './IncomeSetting.vue'
import InventorySetting from './InventorySetting.vue'
import ProductsSetting from './ProductsSetting.vue'
import SuppliersSetting from './SuppliersSetting.vue'
export default {
  components: {
    ExpensesSetting,
    EmployeeSetting,
    IncomeSetting,
    FinancialVariablesSetting,
    GroupsSetting,
    KnTabs,
    SuppliersSetting,
    ProductsSetting,
    InventorySetting
  },
  data() {
    return {
      valueDeterminate: 12.5,
      tabs: [
        { name: 'Egresos', value: 12.5 },
        { name: 'Ingresos', value: 25 },
        { name: 'Empleados', value: 37.5 },
        { name: 'Inventarios', value: 50 },
        { name: 'Grupos', value: 62.5 },
        { name: 'Variables financieras', value: 75 },
        { name: 'Proveedores', value: 87.5 },
        { name: 'Productos', value: 100 }
      ]
    }
  },
  computed: {
    //
  },
  created() {
    this.setIsLogin(false)
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    setTabValue(val) {
      this.valueDeterminate = val
    },
  }
}
</script>

<style>

</style>