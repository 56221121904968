<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} sub categoría de producto`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <kn-text-field
        class="mt-4"
        v-model="subcategory.dato"
        label="Nombre sub categoría:"
        :rules="[rules.required]"
      />
      <kn-select
        v-model="subcategory.id_categoria"
        label="Categoría:"
        :rules="[rules.required]"
        :items="categories"
        item-text="dato"
        item-value="id"
      />

      <v-row>
        <v-col
          class="py-0"
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Código SAT:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-autocomplete
            v-model="subcategory.id_codigo_sat"
            dense
            outlined
            :rules="[rules.required]"
            :items="activeSATCodes"
            item-value="id"
            item-text="descripcion"
          >
            <template #selection="{ item }">
              {{ item.dato }} -  {{ item.descripcion }}
            </template>
            <template #item="{ item }">
              {{ item.dato }} -  {{ item.descripcion }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <kn-doc-item
        class="py-0"
        idInput="subcategoryImg"
        label="Imagen de la sub categoría de producto:"
        @saveFile="saveImage"
        @clearFile="clearImage"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de sub categorías de producto"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeSubcategories">
          <v-list-item :key="item.dato">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === subcategory.id"
                  class="mb-0 pb-0"
                  v-model="subcategory.dato"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.dato"
                />
                <kn-select
                  v-if="toUpdate && item.id === subcategory.id"
                  class="my-0 py-0"
                  v-model="subcategory.id_categoria"
                  :showLabel="false"
                  :rules="[rules.required]"
                  :items="categories"
                  item-text="dato"
                  item-value="id"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.categoria.dato"
                />
                <kn-select
                  v-if="toUpdate && item.id === subcategory.id"
                  class="my-0 py-0"
                  v-model="subcategory.id_codigo_sat"
                  :showLabel="false"
                  :rules="[rules.required]"
                  :items="satCodes"
                  item-text="dato"
                  item-value="id"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.codigo_sat.dato"
                />
                <kn-doc-item
                  v-if="toUpdate && item.id === subcategory.id"
                  class="my-0 py-0"
                  :showLabel="false"
                  colBtn="4"
                  @saveFile="saveImage"
                  @clearFile="clearImage"
                />
                <v-img
                  v-else
                  :src="item.imagen"
                  height="100px"
                  max-width="100px"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === subcategory.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeSubcategories.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnTextField: () => import('../inputs/KnTextField.vue'),
    KnDocItem: () => import('../forms/KnDocItem.vue'),
    KnSelect: () => import('../inputs/KnSelect.vue')
  },
  mixins: [
    validationFormMixin,
    fileUtilsMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      subcategory: {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      subcategories: [],
      categories: [],
      satCodes: [],
      toUpdate: false,
      imageFile: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeSubcategories() {
      return this.subcategories.filter(subcategory => subcategory.estatus_sistema === true)
    },
    invalidFields() {
      // console.log('this.objectHasNulls(this.subcategory) || this.imageFile === null', this.objectHasNulls(this.subcategory) || this.imageFile === null);
      return this.objectHasNulls(this.subcategory) || this.imageFile === null
    },
    activeCategories() {
      return this.categories.filter(category => category.estatus_sistema === true)
    },
    activeSubCategories() {
      return this.subcategories.filter(subCategory => subCategory.estatus_sistema === true)
    },
    activeSATCodes() {
      return this.satCodes.filter(satCode => satCode.estatus_sistema === true)
    }
  },
  async created() {
    this.subcategory.id_autor = this.userData.id
    this.subcategory.id_institucion_educativa = this.institutionId
    this.categories = await this.fetchResultsByEI('productos', 'categoria-producto', this.institutionId)
    this.satCodes = await this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
    this.subcategories = await this.fetchResultsByEI('productos', 'subcategoria-producto', this.institutionId)
  },
  methods: {
    async createCategory() {
      try {
        const responseUpload = await this.uploadFile(this.imageFile)
        if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
          this.subcategory.url_imagen = responseUpload.archivos[0].url_archivo

          const response = await this.postObj('/productos/crear-sub-categoria', {
            sub_categoria: this.subcategory.dato,
            id_categoria: this.subcategory.id_categoria,
            id_codigo_sat: this.subcategory.id_codigo_sat,
            url_imagen: this.subcategory.url_imagen,
            id_institucion_educativa: this.subcategory.id_institucion_educativa,
            id_autor: this.subcategory.id_autor
          })
          if (response.error) {
            this.errors.push(response.error)
          }
        }
      } catch (error) {
        console.error('Error al intentar crear la sub categoria de producto', error)
        this.errors.push('Error al intentar crear la sub categoria de producto')
      }
    },
    async updateCategory() {
      try {
        const responseUpload = await this.uploadFile(this.imageFile)
        if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
          this.subcategory.url_imagen = responseUpload.archivos[0].url_archivo

          const response = await this.postObj('/productos/update-subcategoria-producto', {
            id: this.subcategory.id,
            sub_categoria_producto: this.subcategory.dato,
            id_categoria: this.subcategory.id_categoria,
            id_codigo_sat: this.subcategory.id_codigo_sat,
            url_imagen: this.subcategory.url_imagen
          })
          if (response.error) {
            this.errors.push(response.error)
          }
        }
      } catch (error) {
        console.error('Error al intentar actualizar la sub categoria de producto', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteBrand(subcategoryId) {
      try {
        const response = await this.postObj('/productos/delete-sub-categoria-producto', {
          id_sub_categoria_producto: subcategoryId
        })
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar eliminar la sub categoria de producto', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateCategory()
        this.toUpdate = false
      } else {
        await this.createCategory()
        this.clear()
      }
      this.subcategories = await this.fetchResultsByEI('productos', 'subcategoria-producto', this.institutionId)
      this.clear()
    },
    clear() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.imageFile = null
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.subcategory = {
        id: 'NA', // No asignado
        dato: null,
        id_categoria: null,
        id_codigo_sat: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.imageFile = null
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.subcategory = {...item}
      this.subcategory.id_categoria = item.categoria.id
      this.subcategory.id_codigo_sat = item.codigo_sat.id
      this.subcategory.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.subcategory);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteBrand(itemId)
      this.subcategories = await this.fetchResultsByEI('productos', 'subcategoria-producto', this.institutionId)
      this.clearInline()
    },
    saveImage(obj) {
      this.imageFile = obj
    },
    clearImage() {
      this.imageFile = null
    }
  },
}
</script>
